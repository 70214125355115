<template>
  <div>
    <el-card>
      <el-descriptions :column="2" border>
        <el-descriptions-item label="分子名称">{{
          form.name
        }}</el-descriptions-item>
        <el-descriptions-item label="分子式">{{
          form.molecular_formula
        }}</el-descriptions-item>
        <el-descriptions-item label="CAS号码"
          ><el-tag type="success" effect="plain">{{
            form.cas_number == "" ? "暂无" : form.cas_number
          }}</el-tag></el-descriptions-item
        >
        <el-descriptions-item label="CID号码"
          ><el-tag type="success" effect="plain">{{
            form.pub_chem_cid == "" ? "暂无" : form.pub_chem_cid
          }}</el-tag></el-descriptions-item
        >
        <el-descriptions-item label="创建时间">
          <el-tag type="warning">{{
            timestampToTime(form.create_time)
          }}</el-tag></el-descriptions-item
        >
        <el-descriptions-item label="最近更新时间"
          ><el-tag type="warning">{{
            form.update_time
          }}</el-tag></el-descriptions-item
        >
        <el-descriptions-item label="创建用户"
          ><el-tag type="danger">{{
            form.user_name
          }}</el-tag></el-descriptions-item
        >
      </el-descriptions>
      <el-card class="propertie_card">
        <div slot="header">分子性质</div>
        <WangEditor ref="WangEditor" @refreshContent="refreshContent" />
        <el-row>
          <el-col>
            <span><p>分子性质修改备注</p></span>
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请输入修改理由"
              v-model="remark"
              maxlength="255"
              show-word-limit
            >
            </el-input>
          </el-col>
        </el-row>
      </el-card>
      <el-row type="flex" :gutter="10"
        ><el-col span="10">
          <el-card
            ><div slot="header"><b>分子相关文件</b></div>
            <el-row type="flex" class="file_pannel"
              ><el-col :span=12
                ><span>分子结构文件</span>
                <el-upload
                  action=""
                  disabled
                  :on-preview="handlePreview"
                  ref="upload"
                  :limit="1"
                  :file-list="structFileList"
                >
                  <div slot="tip" class="el-upload__tip">暂无限制</div>
                </el-upload></el-col
              ><el-col :span=12
                ><span>分子计算结果文件</span
                ><el-upload
                  action=""
                  disabled
                  :on-preview="handlePreview"
                  ref="upload"
                  :limit="1"
                  :file-list="calculationResultFileList"
                >
                  <div slot="tip" class="el-upload__tip">暂无限制</div>
                </el-upload></el-col
              ></el-row
            ></el-card
          ></el-col
        ><el-col>
          <el-card
            ><div slot="header">
              <el-row type="flex" justify="space-between" :gutter="10">
                <el-col :span="4"><b>参考文献</b></el-col>
                <el-col :span="16"></el-col>
              </el-row>
            </div>
            <ul v-if="references.length != 0" class="references_pannel">
              <li v-for="(item, index) in references" :key="index">
                <el-card shadow="hover" class="li_card">
                  <el-row type="flex" justify="space-between"
                    ><el-col :span="24"
                      ><span>{{ item.content }}</span></el-col
                    ></el-row
                  >
                </el-card>
              </li>
            </ul>
            <span v-else>暂无信息</span>
          </el-card>
        </el-col></el-row
      >

      <el-row type="flex" justify="end" :gutter="1" class="button_row"
        ><el-col :span="2"
          ><el-button icon="el-icon-close" @click="handleClose"
            >取 消</el-button
          ></el-col
        ><el-col :span="1.5"
          ><el-button type="primary" icon="el-icon-check" @click="savePropertie"
            >保 存</el-button
          ></el-col
        ></el-row
      >
    </el-card>
  </div>
</template>

<script>
import WangEditor from "../../../components/WangEditor/index.vue";
export default {
  components: { WangEditor },
  data() {
    return {
      form: {
        name: "",
        molecular_formula: "",
        cas_number: "",
        pub_chem_cid: "",
        create_time: "",
        update_time: "",
        user_name: "",
      },
      //原来的分子性质内容
      content: "",
      //新的分子性质内容
      newContent: "",
      postData: {
        file_type: "",
        molecule_info_id: -1,
      },
      //修改分子性质的备注
      remark:null,
      //分子结构文件相关参数
      structFileList: [],
      //分子计算结果文件相关参数
      calculationResultFileList: [],
      //参考文献内容
      referenceContent: "",
      //参考文献列表
      references: [],
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      if (this.$route.params.data == undefined) {
        this.$router.push({ name: "MoleculeInfoSelect" });
      } else {
        this.form = this.$route.params.data;
        this.selectFile();
        this.getPropertieRecord();
        this.getReferences();
      }
    },
    //获取分子条目相关文件
    selectFile() {
      this.$store
        .dispatch("moleculeInfo/selectFile", {
          id: this.form.id,
        })
        .then((res) => {
          const { data } = res;
          if (data.struct_file) {
            this.structFileList.push(data.struct_file);
          }
          if (data.calculation_result_file) {
            this.calculationResultFileList.push(data.calculation_result_file);
          }
        });
    },
    //获取分子性质记录
    getPropertieRecord() {
      this.$store
        .dispatch("propertieRecord/select", {
          molecule_info_id: this.form.id,
        })
        .then((res) => {
          if (res.data != undefined && res.data != null) {
            this.content = res.data.content;
            //初始化富文本编辑器
            this.$refs.WangEditor.initHtml(this.content);
          } else {
            this.content = "";
          }
        });
    },
    //编辑更新内容回调函数
    refreshContent(content) {
      this.newContent = content;
    },
    //取消编辑分子性质
    cancelEditPropertie() {
      this.isEditPropertie = false;
    },
    //保存分子性质
    savePropertie() {
      this.$store
        .dispatch("propertieRecord/add", {
          molecule_info_id: this.form.id,
          content: this.newContent,
          remark:this.remark,
        })
        .then((res) => {
          this.$notify({
            title: "编辑成功",
            message:
              "您对分子性质的编辑已经完成，等待相关人员审核完成之后即可显示在分子条目中，您可在“分子性质编辑记录”中查看审核进度。",
            type: "success",
            duration: 5000,
            showClose: false,
          });
          this.newContent = "";
          this.$router.push({ name: "MoleculeInfoSelect" });
        })
        .catch((err) => {
          this.$message.error("出现异常！");
          console.log(err);
        });
      this.handleEditCancel();
    },
    //获取参考文献列表
    getReferences() {
      this.$store
        .dispatch("references/select", {
          molecule_info_id: this.form.id,
        })
        .then((res) => {
          var { data } = res;
          this.references = [];
          data.forEach((item) => {
            this.references.push(item);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClose() {
      this.$router.push({ name: "MoleculeInfoSelect" });
    },
    //时间戳转换为时间
    timestampToTime(timestamp) {
      var date = new Date(parseInt(timestamp)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000,需要将字符串转化为int
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
};
</script>

<style scoped>
.propertie_pannel {
  height: 15rem;
  overflow-y: overlay !important;
  overflow-x: hidden !important;
}
.propertie_card::v-deep .el-card__body {
  padding: 10px !important;
}
.file_pannel {
  height: 7.5rem;
  overflow-y: overlay !important;
  overflow-x: hidden !important;
}
.references_pannel {
  height: 7.5rem;
  overflow-y: overlay !important;
  overflow-x: hidden !important;
}
.button_row {
  margin-top: 5vh;
  padding-right: 2vh;
}
</style>